@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** base colors **/

  --black-color-base: #000000;
  --black-color-base-rgb: 0, 0, 0;

  --blue-color-base: #4874b7;
  --blue-color-base-rgb: 72, 116, 183;

  --dark-color-base: #383838;
  --dark-color-base-rgb: 56, 56, 56;
  --dark-color-shade: #1c1c1c;
  --dark-color-tint: #545454;

  --gold-color-base: #d5a746;
  --gold-color-base-rgb: 213, 167, 70;
  --gold-color-shade: #b78929;
  --gold-color-tint: #d19f36;

  --gray-color-base: #9b9b9b;
  --gray-color-base-rgb: 155, 155, 155;
  --gray-color-shade: #696969;
  --gray-color-tint: #cdcdcd;

  --green-color-base: #4ab678;
  --green-color-base-rgb: 74, 182, 120;
  --green-color-shade: #398c5c;
  --green-color-tint: #58bc82;

  --light-color-base: #f9f9f9;
  --light-color-base-rgb: 249, 249, 249;
  --light-color-shade: #e5e5e5;
  --light-color-tint: #faf8f8;

  --orange-color-base: #da6525;
  --orange-color-base-rgb: 218, 101, 37;
  --orange-color-shade: #b8551f;
  --orange-color-tint: #dd7035;

  --purple-color-base: #801747;
  --purple-color-base-rgb: 128, 23, 71;
  --purple-color-shade: #6b133b;
  --purple-color-tint: #550f2f;

  --red-color-base: #eb2717;
  --red-color-base-rgb: 235, 39, 23;
  --red-color-shade: #c91f11;
  --red-color-tint: #ed3829;

  --rose-color-base: #b5796f;
  --rose-color-base-rgb: 181, 121, 111;
  --rose-color-shade: #9a5a4f;
  --rose-color-tint: #ae6d62;

  --white-color-base: #ffffff;
  --white-color-base-rgb: 255, 255, 255;

  --ion-font-family: 'Inter', sans-serif;
  --title-font-family: 'Inter', sans-serif;

  --ion-text-color: var(--black-color-base);
  --ion-text-color-rgb: var(--black-color-base-rgb);

  /** primary **/
  --ion-color-primary: var(--purple-color-base);
  --ion-color-primary-rgb: var(--purple-color-base-rgb);
  --ion-color-primary-contrast: var(--white-color-base);
  --ion-color-primary-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-primary-shade: var(--purple-color-shade);
  --ion-color-primary-tint: var(--purple-color-tint);

  /** secondary **/
  --ion-color-secondary: var(--gold-color-base);
  --ion-color-secondary-rgb: var(--gold-color-base-rgb);
  --ion-color-secondary-contrast: var(--white-color-base);
  --ion-color-secondary-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-secondary-shade: var(--gold-color-shade);
  --ion-color-secondary-tint: var(--gold-color-tint);

  /** tertiary **/
  --ion-color-tertiary: var(--rose-color-base);
  --ion-color-tertiary-rgb: var(--rose-color-base-rgb);
  --ion-color-tertiary-contrast: var(--white-color-base);
  --ion-color-tertiary-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-tertiary-shade: var(--rose-color-shade);
  --ion-color-tertiary-tint: var(--rose-color-tint);

  /** success **/
  --ion-color-success: var(--green-color-base);
  --ion-color-success-rgb: var(--green-color-base-rgb);
  --ion-color-success-contrast: var(--white-color-base);
  --ion-color-success-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-success-shade: var(--green-color-shade);
  --ion-color-success-tint: var(--green-color-tint);

  /** warning **/
  --ion-color-warning: var(--orange-color-base);
  --ion-color-warning-rgb: var(--orange-color-base-rgb);
  --ion-color-warning-contrast: var(--white-color-base);
  --ion-color-warning-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-warning-shade: var(--orange-color-shade);
  --ion-color-warning-tint: var(--orange-color-tint);

  /** danger **/
  --ion-color-danger: var(--red-color-base);
  --ion-color-danger-rgb: var(--red-color-base-rgb);
  --ion-color-danger-contrast: var(--white-color-base);
  --ion-color-danger-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-danger-shade: var(--red-color-shade);
  --ion-color-danger-tint: var(--red-color-tint);

  /** dark **/
  --ion-color-dark: var(--dark-color-base);
  --ion-color-dark-rgb: var(--dark-color-base-rgb);
  --ion-color-dark-contrast: var(--white-color-base);
  --ion-color-dark-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-dark-shade: var(--dark-color-shade);
  --ion-color-dark-tint: var(--dark-color-tint);

  /** medium **/
  --ion-color-medium: var(--gray-color-base);
  --ion-color-medium-rgb: var(--gray-color-base-rgb);
  --ion-color-medium-contrast: var(--white-color-base);
  --ion-color-medium-contrast-rgb: var(--white-color-base-rgb);
  --ion-color-medium-shade: var(--gray-color-shade);
  --ion-color-medium-tint: var(--gray-color-tint);

  /** light **/
  --ion-color-light: var(--light-color-base);
  --ion-color-light-rgb: var(--light-color-base-rgb);
  --ion-color-light-contrast: var(--black-color-base);
  --ion-color-light-contrast-rgb: var(--black-color-base-rgb);
  --ion-color-light-shade: var(--light-color-shade);
  --ion-color-light-tint: var(--light-color-tint);
}

/* Typography */

a {
  color: #4874b7;
  color: var(--blue-color-base);
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  font-family: var(--title-font-family);
}

.assistive-text + .assistive-text {
  display: none;
}

body {
  background-color: #FCF9F9;
}

ion-content {
  --background: #FCF9F9;
}

ion-card-title {
  font-size: 20px;
  font-weight: 300;
}
